*{
  padding: 0%;
  margin: 0%;
}

nav {
  margin: 0px;
  font-size: 20px;
  background-color: #0899BA;
  box-shadow: 5px 5px 5px #0899BA;
  display: flex;
  justify-content: space-between;
  padding: 10px;

}

.navImage {
  background-image: url(../Assests/elcharq.jpg);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  width: auto;
  padding: 2%;
  display: inline-block;
  
}

.navItems {
  padding-top: 15px;
  display: flex;
  align-items: center;
  font-size: 25px;
  
}

.navItem {
  /* text-decoration: none; */
  padding: 10px;
  color: #1A5B92;
  
}

.navItem:hover {
  background-color: #ffff;
  color: #0899BA;
  border-radius: 20px;
  transition: 0.5s;
  font-size: 20px;
  /* text-decoration: none !important; */

}

.activeNavItem {
  background-color: #ffff;
  color: #0899BA;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  text-decoration: none !important;
}
.navItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.navToggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 5%; 
  right: 10px; 
  /* transform: translateY(-50%); Center the collapse icon vertically */
  z-index: 1;
}

.navToggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  margin-bottom: 4px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}
.dropdown-menu{
  background-color: #0899BA;
  color: #1A5B92;
  border-radius: 20px;
  text-decoration: none;
}
.dropdown-menu:hover{
padding: 10px;
margin: 10px;
font-size: 20px;
text-decoration: none !important;

}
.dropdown-item{
  text-decoration: none;
  border-radius: 20px;
  font-size: 18px;
  text-decoration: none !important;
}
.dropdown-item:focus{
  background-color: #0899BA;
  color: #1A5B92;
}

@media (max-width: 768px) {
  .navItems {
    display: none;
    flex-direction: column-reverse !important;
  }

  .navItems.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #0899BA;
    padding: 20px;
    position: absolute;
    top: calc(5% + 20px); 
    right: 0;
    z-index: 1;
  }

  .navItem {
    margin-bottom: 0.5rem;
  }

  .navToggle {
    display: block;
  }
}
/* ..............................footer................. */
.all {
    background-color: #0899BA;
  }

  
  .footer {
    margin: 0px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    align-items: center; /* Center align items vertically */
  }
  .social{
    display: inline-block;
    width: auto;

  }
  .allFo {
    display: grid;
    justify-content: center;
    text-align: center;
    padding-right: 10%;
  }
  
  .navImageFo {
    background-image: url(../Assests/elcharq.jpg);
    width: 100px;
    height: 100px;
    margin-right: 20%;
    margin-top: 20%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20%; /* Make the image circular */
  }
  
  .listItem {
    padding-right: 20px;
    color: #1A5B92;
    text-align: right;
  }
  
  .endFo {
    text-align: center;
    padding: 20px 0; /* Add vertical padding */
    color: #1A5B92;
  }
  
  .alll {
    margin: 0px;
    padding-right: 20px;
    justify-content: space-between;
    display: flex;
    align-items: center; /* Center align items vertically */
  }
  
  .icons {
    padding: 60px;
    display: flex;
    justify-content: flex-start; /* Align icons to the left */
    align-items: center;
  }
  
  .icons i {
    width: 50px;
    margin-right: 10px; /* Add spacing between icons */
    color: white; /* Set icon color to white */
  }
.fa-brands{

    font-size: 40px;
  }
.fa-users{
  font-size: 30px;

}
.fa-users:hover{
  color: #1A5B92;

}
  .you:hover{
    color: rgb(209, 27, 27);
  }
  .fac:hover{
    color: #1A5B92;
  }
          /* Media queries for responsive design */
          @media (max-width: 768px) {
            .footer {
              flex-direction: column;
              align-items: center;
            }
            
            .social {
              margin-bottom: 1rem;
            }
            
            .listItem {
              text-align: center;
            }
            .navImageFo{
              margin: 0px auto;
            }
          }
          
          @media (max-width: 480px) {
            .icons {
              flex-direction: column;
              align-items: center;
            }
            
            .icons a {
              margin-bottom: 0.5rem;
            }
            .navImageFo{
              margin: 0px auto;
            }
          }
        
/* ...........................................card.............................................. */
.Card{
    padding: 20px;
    width: 350px;
    display: grid;
    border: none;
}
.Card .image{
    width: 350px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    }
.Card h4{
    justify-content: center;
    text-align: center;
    font-family: 'Cairo', sans-serif; 
    font-size: 25px;
    color:#1A5B92;
}