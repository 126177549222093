*{
  margin: 0px;
  padding: 0px;
  line-height: 4rem;
  direction: rtl;
}
.Home{
  padding: 20px;
}
.Home h5{
    text-align: right;
    font-family: 'Cairo', sans-serif;
    font-size: 30px;
    padding: 20px;
}
.Home p{    
    color:#ffff;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    background-color:#1A5B92 ;
    padding: 3%;
    text-align: center;

}
.Home #link{
    color:black;
}
.Home #link:hover{
    color:#1A5B92;
}


/*.......................................... our us........................................... */
.OurUs{
    text-align: right;
    font-size: 28px;
    padding: 20px;
    font-family: 'Cairo', sans-serif;
    font-weight: 500;
    
}
.OurUs p {
    font-size: 20px;
    text-align: right;
    color:#1A5B92;
}
/* **********************************************Seeing********************** */
.Seeing {
  text-align: right;
  padding: 20px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}
.Seeing h2 {
  font-size: 25px;
  text-align: right;
  color: black;
  padding: 3px;
  font-weight: bold;
  line-height: 4rem;
}

.Seeing h5 {
  font-size: 20px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.Seeing p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}
.Seeing ul li ul li p{
    font-size: 18px;
      text-align: right;
      color: black;
      justify-content: right;

}

.Seeing ul ol {
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;
}
.contantInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right:4% ;
  width: 300px;
}

.team-page {
  padding: 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.member-name{
  color:#1A5B92;
}
.admin-page{
    padding: 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

}

.team-member {
  text-align: center;
}

.member-image {
  width: 100%;
  border-radius: 50%; 
  border: 2px solid #ddd; 
}

.member-name {
  margin-top: 10px;
  font-size: 2em;
}

.member-position {
  font-size: 1.3em;
  color: #666;
}


.member-image {
  width: 350px;
  height: 300px;
}
/* ......................................jobs................................... */

.Jobs {
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.Jobs h4 {
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.Jobs p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.Jobs ul ol {
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;

}

    .Care {
      text-align: right;
      padding: 30px;
      margin: 10px;
      line-height: 3rem;
      font-family: 'Cairo', sans-serif;
      
    }
  
    .Care h4 {
      font-size: 25px;
      text-align: right;
      color: #003459;
      font-weight: bold;
      line-height: 4rem;
    }
  
    .Care p {
      font-size: 22px;
      text-align: right;
      color: #1A5B92;
      justify-content: right;
    }
  
    .Care ul ol {
      font-size: 20px;
      text-align: right;
      color: #1A5B92;
      /* justify-content: right; */
      direction: rtl;
      counter-reset: li;
      padding-right: 30px;
    }
/* ............................Activity................................................ */

.Activity {
  text-align: right;
  font-family: 'Cairo', sans-serif;
  background-color: #f2f2f2;
  padding: 50px;
  margin: 0;
  
}
.Activity .link{
  font-size: 20px;
  color: white;
  background-color: #1A5B92;
  text-decoration: none;
  padding: 10px;
  border-radius: 20px;

}
.Activity .link:hover{
  padding: 20px;
}
.Activity .container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Activity  h1 {
  text-align: center;
  color: #333;
}

.Activity  .event {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.Activity .event h2 {
  color: #333;
  font-size: 22px;
  margin-bottom: 10px;
}

.Activity .event p {
  color:#1A5B92;
  font-size: 20px;
}


/* ..................tranig.............................................. */
.traning {
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.traning h4 {
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.traning p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.traning ul ol {
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;

}
  .comCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.comCard .card {
display: grid;
margin: 20px;
width: 400px;
}
.title{
    text-align: right;
    padding: 20px;
    font-size: 30px;
    text-shadow: 1px 1px #1A5B92;
    color: black;
} 
/* .......................Projects...................................... */
.Projects {
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.Projects h4 {
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.Projects p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.Projects ul ol {
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;
}
.Projects h2 {
  font-size: 27px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;

}

/* ***************************money************************************ */
.money{
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}
.money h4 {
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}
.money p{
      font-size: 22px;
      text-align: right;
      color: #1A5B92;
      justify-content: right;
}
.money ul ol{
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
    direction: rtl;
    counter-reset: li;
    padding-right: 30px;
  
}
/***********Media ********/
.Media {
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.Media h4 {
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.Media p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.Media ul ol {
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;
}
/****************************DigitalConversion **********/
.DigitalConversion {
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.DigitalConversion h4 {
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.DigitalConversion p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.DigitalConversion ul ol {
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;
}
/*******************************HumanResourse**************/
.HumanResourse , .Shopping,.Companies{
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.HumanResourse h4 ,.Shopping h4,.Companies h4{
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.HumanResourse p, .Shopping p , .Companies p {
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.HumanResourse ul ol , .Shopping ul ol, .Companies ul ol{
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;
}
/**************************SocialReserch and .Evaluatio******/
.SocialReserch,.Evaluation,.Services, .Product ,.Replacement,.MakeThings,.HandMade{
  text-align: right;
  padding: 30px;
  margin: 10px;
  line-height: 3rem;
  font-family: 'Cairo', sans-serif;
}

.SocialReserch h4 ,.Evaluation h4 , .Services h4, .Product h4,.Replacement h4,.MakeThings h4,.HandMade h4{
  font-size: 25px;
  text-align: right;
  color: #003459;
  font-weight: bold;
  line-height: 4rem;
}

.SocialReserch p ,.Evaluation p, .Services p, .Product p, .Replacement p, .MakeThings p, .HandMade p{
  font-size: 22px;
  text-align: right;
  color: #1A5B92;
  justify-content: right;
}

.SocialReserch ul ol ,.Evaluation ul ol ,.Services ul ol ,.Product ul ol,.Replacement ul ol ,.MakeThings ul ol,.HandMade ul ol{
  font-size: 20px;
  text-align: right;
  color: #1A5B92;
  /* justify-content: right; */
  direction: rtl;
  counter-reset: li;
  padding-right: 30px;
}
.Evaluation h2, .Services h2 , .Product h2{
      font-size: 27px;
      text-align: right;
      color: #003459;
      font-weight: bold;
      line-height: 4rem;

}
.img{
      width: 500px;;
      height: 500px;

}
.imge{
  background-image: url(../Assests/sewing/sewing7.jpg);
    width: 350px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
}
.img .link {
  font-size: 20px;
  color: white;
  background-color: #1A5B92;
  text-decoration: none;
  padding: 10px;
  border-radius: 20px;
  margin-right: 20%;

}
.imgeRe{
    background-image: url(../Assests/Activity2//gro/re4.jpg);
      width: 350px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;

}
.imgeS{
  background-image: url(../Assests/sell/9.jpg);
    width: 350px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;

}
.imgem{
      background-image: url(../Assests/media/1.jpg);
        width: 350px;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;

}
.imgeH {
  background-image: url(../Assests/humman/2.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.cooo{
  display: inline-flex;

}
.cooo .pra{
  margin-left: 40% !important;
}
.imgeC{
  display: flex;
    background-image: url(../Assests/coo/resala2.jpg);
      width: 350px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;

}
.imgec2 {
  display: flex;
  background-image: url(../Assests/coo/helwan1.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.imgeP {
  display: flex;
  background-image: url(../Assests/projects/project4.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}

.imgeP1 {
  display: flex;
  background-image: url(../Assests/projects/project2.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.imgeRR1 {
  display: flex;
  background-image: url(../Assests/Activity2/feed/2.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}

.imgeRR {
  display: flex;
  background-image: url(../Assests/Activity2/help/2.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.imagHa{
      background-image: url(../Assests/handmad/5.jpg);
        width: 350px;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;

}
.imgeD {
  background-image: url(../Assests/computer/computer2021.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.imgeT {
  background-image: url(../Assests/woman/1.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.imgeTra {
  background-image: url(../Assests/loom/loom1.jpg);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

}
.img p{
  margin-right: 12%;

}

.img .link:hover {
  padding: 15px;
}